// POPPINS
@font-face {
  font-family: Poppins;
  font-weight: 300;
  src: url("../fonts/Poppins/Poppins-Light.ttf");
}
@font-face {
  font-family: Poppins;
  font-weight: 400;
  src: url("../fonts/Poppins/Poppins-Regular.ttf");
}
@font-face {
  font-family: Poppins;
  font-weight: 500;
  src: url("../fonts/Poppins/Poppins-Medium.ttf");
}
@font-face {
  font-family: Poppins;
  font-weight: 600;
  src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: Poppins;
  font-weight: 700;
  src: url("../fonts/Poppins/Poppins-Bold.ttf");
}

// POPPINS
@font-face {
  font-family: Lexend;
  font-weight: 300;
  src: url("../fonts/Lexend/Lexend-Light.ttf");
}
@font-face {
  font-family: Lexend;
  font-weight: 400;
  src: url("../fonts/Lexend/Lexend-Regular.ttf");
}
@font-face {
  font-family: Lexend;
  font-weight: 500;
  src: url("../fonts/Lexend/Lexend-Medium.ttf");
}
@font-face {
  font-family: Lexend;
  font-weight: 600;
  src: url("../fonts/Lexend/Lexend-SemiBold.ttf");
}
@font-face {
  font-family: Lexend;
  font-weight: 700;
  src: url("../fonts/Lexend/Lexend-Bold.ttf");
}

//
html body {
  @apply font-body antialiased;
}

h1,
h2,
h3,
h4 {
  @apply font-display;
}

:root {
  --font-display: Lexend;
  --font-body: Lexend;
}

.theme-animals {
  --font-display: Inter;
  --font-body: Inter;
}
