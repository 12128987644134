// Change your color you want --- please input rgb color (ex: 22,33,44)

// THEME PALETTE 9 ==========================================
:root {
  --c-primary-50: 255, 255, 255; // #FFFFFF
  --c-primary-100: 255, 255, 255; // #FFFFFF
  --c-primary-200: 220, 233, 254; // #DCE9FE
  --c-primary-300: 181, 207, 252; // #B5CFFC
  --c-primary-400: 142, 182, 251; // #8EB6FB
  --c-primary-500: 102, 156, 249; // #669CF9
  --c-primary-600: 63, 131, 248;  // #3F83F8
  --c-primary-700: 9, 96, 246;    // #0960F6
  --c-primary-800: 7, 75, 192;    // #074BC0
  --c-primary-900: 5, 54, 138;    // #05368A
  --c-primary-950: 3, 36, 92;     // #03245C
  // SECONDARY COLOR
  
  --c-secondary-50: 255, 255, 255;
  --c-secondary-100: 251, 249, 255;
  --c-secondary-200: 226, 211, 252;
  --c-secondary-300: 201, 173, 250;
  --c-secondary-400: 176, 135, 247;
  --c-secondary-500: 151, 96, 245;
  --c-secondary-600: 126, 58, 242;
  --c-secondary-700: 94, 15, 229;
  --c-secondary-800: 72, 12, 176;
  --c-secondary-900: 51, 8, 124;
  --c-secondary-950: 34, 5, 83;
  /*
  --c-secondary-50: 254, 244, 255;
  --c-secondary-100: 253, 233, 254;
  --c-secondary-200: 249, 210, 252;
  --c-secondary-300: 247, 174, 249;
  --c-secondary-400: 244, 125, 245;
  --c-secondary-500: 232, 76, 233;
  --c-secondary-600: 212, 53, 210;
  --c-secondary-700: 170, 33, 165;
  --c-secondary-800: 139, 29, 134;
  --c-secondary-900: 114, 29, 109;
  --c-secondary-950: 76, 6, 71;
  */
  /*
  --c-secondary-50: 252, 244, 255;
  --c-secondary-100: 248, 233, 254;
  --c-secondary-200: 240, 210, 252;
  --c-secondary-300: 230, 174, 249;
  --c-secondary-400: 217, 125, 245;
  --c-secondary-500: 197, 76, 233;
  --c-secondary-600: 178, 53, 212;
  --c-secondary-700: 144, 33, 170;
  --c-secondary-800: 120, 29, 139;
  --c-secondary-900: 100, 29, 114;
  --c-secondary-950: 65, 6, 76;
  */
  /*
  --c-secondary-50: 250, 246, 254;
  --c-secondary-100: 244, 234, 253;
  --c-secondary-200: 234, 217, 251;
  --c-secondary-300: 218, 187, 247;
  --c-secondary-400: 195, 144, 240;
  --c-secondary-500: 172, 101, 231;
  --c-secondary-600: 141, 53, 212;
  --c-secondary-700: 129, 51, 189;
  --c-secondary-800: 109, 47, 154;
  --c-secondary-900: 89, 39, 124;
  --c-secondary-950: 60, 16, 91;
  */
  // NEUTRAL COLOR
  --c-neutral-50: 249, 250, 251;
  --c-neutral-100: 243, 244, 246;
  --c-neutral-200: 229, 231, 235;
  --c-neutral-300: 209, 213, 219;
  --c-neutral-400: 156, 163, 175;
  --c-neutral-500: 107, 114, 128;
  --c-neutral-600: 75, 85, 99;
  --c-neutral-700: 55, 65, 81;
  --c-neutral-800: 31, 41, 55;
  --c-neutral-900: 17, 24, 39;
  
}
