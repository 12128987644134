.root {
  overflow: hidden;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hiddenScrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hiddenScrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.customScrollBar {
  /* width */
  &::-webkit-scrollbar {
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

//

.nc-will-change-top {
  will-change: top;
}
//

.nc-SectionClientSay .glide__bullet--active {
  @apply bg-neutral-700;
}
.nc-GallerySlider .glide__bullet--active {
  @apply bg-white w-2 h-2;
}

//
.nc-card-title {
  @apply transition-colors  hover:text-primary-900 dark:hover:text-primary-300 duration-75;
}

//
.nc-PostCardCommentBtn,
.nc-PostCardLikeAction,
.nc-CommentCardLikeReply > button {
  &:hover {
    span {
      color: inherit;
    }
  }
}

//
.nc-box-has-hover {
  @apply bg-white shadow-xl rounded-3xl border border-neutral-200/70 hover:bg-neutral-50 hover:shadow-xl hover:border-transparent transition-shadow;
}

.nc-dark-box-bg-has-hover {
  @apply dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:hover:bg-neutral-800 dark:hover:shadow-2xl;
}

.nc-dark-box-bg {
  @apply dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:shadow-2xl;
}

//
.nc-origin-100 {
  transform-origin: 100% 50% 0px;
}
.nc-origin-50 {
  transform-origin: 50% 50% 0px;
}

// HERO
.nc-hero-field-padding {
  @apply py-3 px-4 md:py-4 md:px-6 xl:py-6 xl:px-8;
}
.nc-hero-field-focused {
  @apply shadow-2xl rounded-full dark:bg-white/5;
}

.nc-flex-1 {
  flex: 1 0 0%;
}

.nc-flex-1\.5 {
  flex: 1.5 0 0%;
}
.nc-flex-1\.5-auto {
  flex: 1.5 0 auto;
}

.nc-flex-2 {
  flex: 2 0 0%;
}

.nc-flex-2-auto {
  flex: 2 0 auto;
}

.nc-flex-2\.5 {
  flex: 2.5 0 0%;
}

@screen lg {
  .lg\:nc-flex-1\.5 {
    flex: 1.5 0 0%;
  }
  .lg\:nc-flex-1\.5-auto {
    flex: 1.5 0 auto;
  }

  .lg\:nc-flex-2 {
    flex: 2 0 0%;
  }

  .lg\:nc-flex-2-auto {
    flex: 2 0 auto;
  }

  .lg\:nc-flex-2\.5 {
    flex: 2.5 0 0%;
  }
}

.nc-icon-field {
  @apply w-5 h-5 lg:w-7 lg:h-7;
}
//
button.gm-control-active.gm-fullscreen-control {
  @apply rounded-xl #{!important};
}

//
.nc-FiveStartIconForRate {
  svg:hover ~ svg {
    @apply text-neutral-300;
  }
}

// LISTING PAGE DETAIL
.listingSection__wrap {
  @apply w-full flex flex-col sm:rounded-2xl border-b sm:border-t sm:border-l sm:border-r border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 pb-10 px-0 sm:p-4 xl:p-8;
}

//
input[type="time"]::-webkit-calendar-picker-indicator {
  @apply dark:bg-neutral-500 rounded-md;
}

[type="button"] {
  -webkit-appearance: none !important;
}

//
.nc-CardLarge1--hasAnimation {
  .nc-CardLarge1__left {
    animation-name: CardLarge1ExampleLeft;
    animation-duration: 300ms;
    animation-timing-function: ease-in-out;
  }
  .nc-CardLarge1__right {
    animation-name: CardLarge1ExampleRight;
    animation-duration: 500ms;
    animation-timing-function: ease-in-out;
  }
}

@keyframes CardLarge1ExampleRight {
  from {
    transform: scale(1.05) translateY(20px);
    opacity: 0;
  }
  to {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}
@keyframes CardLarge1ExampleLeft {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

// fix for mantine select input
input {
  --tw-ring-shadow: 0 0 #000 !important;
}

.mantine-MultiSelect-input {
  @apply dark:bg-slate-800 dark:border-slate-600
}

.mantine-MultiSelect-searchInput {
  @apply dark:bg-slate-800
}


//Tiptap fixes
.ProseMirror p {
  list-style: auto;
  margin: 0 0;
}

.ProseMirror ul {
  list-style: circle;
}

.ProseMirror ol {
  list-style: auto;
}

.ProseMirror {
  p {
    &[style*="text-align: center"] {
      img {
        display: block;
        margin: 0 auto;
      }
    }

    &[style*="text-align: left"] {
      img {
        float: left;
        margin-right: 1rem;
      }
    }

    &[style*="text-align: right"] {
      img {
        float: right;
        margin-left: 1rem;
      }
    }
  }
}

.animate-shimmer {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #f0f0f0 25%, #cfcdcd 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.dark .animate-shimmer {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #1a1a1a 25%, #2a2a2a 50%, #1a1a1a 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

.animate-shimmer-bg{
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
}

.dark .animate-shimmer-bg{
  background: linear-gradient(90deg, #1a1a1a 25%, #2a2a2a 50%, #1a1a1a 75%);
}

@keyframes shimmer {
  to {
    background-position: -200% 0;
  }
}

.dark .ProseMirror {
  background-color: #0e131f;
  color: #fff;
  border-radius:0.25rem;
}

.ProseMirror {
  background-color: fff;
  color: #0e131f;
  border-radius:0.25rem;
}

 .mantine-1drwy5n {
  background-color: transparent;
}

.dark .onchain-icon-color {
  color: #fff;
  stroke: #fff;
}

 .onchain-icon-color {
  color: #000;
  stroke: #000;
}

.bg-custom-tag {
  background-color: #27272a;
}

.dark .bg-custom-tag{
  background-color: #fff;
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-thumb {
  background-color: grey; /* Change this to your desired scrollbar color */
  border-radius: 4px;
}

.rc-slider-track {
  position: absolute;
  height: 4px;
  background-color: #3f83f8!important;
  border-radius: 6px;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 0.75s ease forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

button[aria-controls=":r3:-tabpanel-0"][aria-selected="true"][class*="text-blue-600"][class*="border-blue-600"] {
  outline: -webkit-focus-ring-color auto 0px;
}

@keyframes scroll {
  from {
      transform: translateX(0%);
  }
  to {
      transform: translateX(-100%);
  }
}

.animate-scroll ul {
  animation: scroll infinite linear;
}

.no-visible-scrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
}
 
.no-visible-scrollbar::-webkit-scrollbar {
  display: none;
}

.dark .mantine-Input-input{
  background-color: neutral-900;
  border-color: gray-700;
}

.dark .mantine-Select-input{
 background-color: neutral-900;
  border-color: gray-700;
}

.dark .mantine-Input-input{
  background-color: neutral-900;
  border-color: gray-700;
}

.dark {
  .mantine-Input-input, .mantine-Select-input {
    @apply bg-neutral-900 border-gray-700 text-white;
  }
  .mantine-Modal-header {
    @apply bg-neutral-700 text-white;
  }
  .mantine-Modal-body {
    @apply bg-neutral-700 text-white;
  }
  .mantine-Modal-close {
    @apply text-white;
  }
  .mantine-Modal-close:hover {
    @apply text-black;
  }
}